<template>
  <div class="maxbox">
    <div>
      <div class="top">
        <p>WEX</p>
        <!-- <div id="walletAddress">{{ shortWalletAddress }}</div>
        <div id="walletBalanceTop">金额: {{ walletBalanceTop }} USDT</div>
        <div id="walletBalanceScore">积分: {{ walletScore }} 分</div> -->
      </div>
      <div class="main">
        <div class="itemheader">
          <img src="@/assets/images/ban-2.png" alt="" />
          <div>
            <h2>WEX ECOL OGY</h2>
            <p>开创区块链新时代</p>
          </div>
        </div>

        <h3 class="title">WEX生态板块图</h3>

        <div class="card">
          <form @submit.prevent="handleSubmit">
            <label for="address">收货地址:</label>
            <input type="text" id="address" v-model="addressInfo" required class="input-field" />
            <label for="phone">姓名/电话号码:</label>
            <input type="tel" id="phone" v-model="phoneNumber" required class="input-field" />
            <button type="submit" v-if="walletAddress && !submittedWallets.has(walletAddress)">提交</button>
          </form>
          <div v-if="submittedWallets.has(walletAddress)" class="status">
            <span :class="{'shipped': isShipped}">
              {{ isShipped ? '已发货,请注意查收!' : '' }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from 'ethers';
import { contractAddress, addressStorageAbi } from '@/js/contracts.js';

export default {
  data() {
    return {
      addressInfo: '',
      phoneNumber: '',
      submitted: false,
      walletAddress: null,
      shortWalletAddress: '未连接',
      walletBalanceTop: '0',
      walletScore: 0,
      submittedWallets: new Set(),
      isShipped: false
    };
  },
  methods: {
    async connectWallet() {
      if (typeof window.ethereum !== 'undefined') {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
          await provider.send("eth_requestAccounts", []);
          const signer = provider.getSigner();
          this.walletAddress = await signer.getAddress();
          this.shortWalletAddress = `地址:${this.walletAddress.slice(0, 5)}...${this.walletAddress.slice(-4)}`;
          console.log('Connected wallet:', this.walletAddress);
          this.loadUserInfo();
          this.loadWalletScore(); // 加载钱包积分
        } catch (error) {
          console.error('钱包连接失败:', error);
        }
      } else {
        alert('请安装 MetaMask!');
      }
    },
    async handleSubmit() {
      if (this.walletAddress && !this.submittedWallets.has(this.walletAddress)) {
        if (confirm('你确认要提交吗？提交后将扣除2980积分且无法再次修改。')) {
          if (this.walletScore >= 2980) {
            this.walletScore -= 2980;  // 积分扣除在客户端管理
            this.saveWalletScore();  // 保存扣除后的积分

            try {
              const provider = new ethers.providers.Web3Provider(window.ethereum);
              const signer = provider.getSigner();
              const addressStorageContract = new ethers.Contract(contractAddress, addressStorageAbi, signer);
              const tx = await addressStorageContract.storeAddress(this.addressInfo, this.phoneNumber);
              console.log('Transaction:', tx);
              await tx.wait(); // 等待交易打包完成
              alert('提交成功，请等待发货！');
              this.submitted = true;

              const deliveryInfo = {
                address: this.addressInfo,
                phone: this.phoneNumber,
                walletAddress: this.walletAddress,
                shipped: false
              };
              let deliveryAddresses = JSON.parse(localStorage.getItem('deliveryAddresses')) || [];
              deliveryAddresses.push(deliveryInfo);
              localStorage.setItem('deliveryAddresses', JSON.stringify(deliveryAddresses));
              localStorage.setItem(`submitted_${this.walletAddress}`, 'true');
              this.submittedWallets.add(this.walletAddress);
            } catch (error) {
              console.error('提交到区块链失败:', error);
              alert('提交失败，请重试');
            }
          } else {
            alert('积分不足，无法提交');
          }
        }
      }
    },
    async loadUserInfo() {
      if (this.walletAddress) {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const addressStorageContract = new ethers.Contract(contractAddress, addressStorageAbi, signer);
          const [addressInfo, phoneNumber] = await addressStorageContract.getAddress();
          this.addressInfo = addressInfo;
          this.phoneNumber = phoneNumber;

          this.loadSavedData();
        } catch (error) {
          console.error('无法加载用户信息:', error);
        }
      }
    },
    loadSavedData() {
      let deliveryAddresses = JSON.parse(localStorage.getItem('deliveryAddresses')) || [];
      deliveryAddresses.forEach(item => {
        this.submittedWallets.add(item.walletAddress);
        if (item.walletAddress === this.walletAddress && item.shipped) {
          this.isShipped = true;
        }
      });

      if (this.walletAddress && localStorage.getItem(`submitted_${this.walletAddress}`) === 'true') {
        this.submitted = true;
      }
    },
    loadWalletScore() {
      const savedScore = localStorage.getItem(`walletScore_${this.walletAddress}`);
      if (savedScore !== null) {
        this.walletScore = parseInt(savedScore, 10);
      }
    },
    saveWalletScore() {
      localStorage.setItem(`walletScore_${this.walletAddress}`, this.walletScore);
    }
  },
  mounted() {
    this.connectWallet();
  }
};
</script>

<style scoped>
@import "@/assets/css/item.css";
.input-field {
  height: 30px;
}
.card {
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
  background-color: #f0f0f0;
  overflow: auto;
  max-height: 200px;
}
form {
  display: flex;
  flex-direction: column;
}
label {
  margin-bottom: 10px;
}
input {
  margin-bottom: 10px;
}
button {
  padding: 10px;
  border-radius: 4px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}
button:hover {
  background-color: #0056b3;
}
#walletAddress, #walletBalanceTop {
  color: rgb(255, 102, 0);
}
.status {
  margin-top: 10px;
}
.shipped {
  color: red;
}
</style>