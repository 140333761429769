<template>
  <div class="maxbox">
    <div>
      <div class="top">
        <button class="return" @click="goBack">
          <img src="@/assets/images/left-arrow.png" alt="返回" />
        </button>
        <p>收货地址</p>
      </div>
      <div class="main">
        <!-- <div class="wallet-info">
          <p><strong>当前钱包地址:</strong> {{ truncatedAccount }}</p>
          <p v-if="isOwner"><strong>您是合约部署者</strong></p>
          <p v-else><strong>您不是合约部署者</strong></p>
        </div> -->
        <div v-if="userAddresses.length > 0">
          <div v-for="(user, index) in userAddresses" :key="index" class="address-item">
            <div>
              <label :for="'address-' + index"><strong>地址信息:</strong></label>
              <textarea
                :id="'address-' + index"
                :value="addressText(user)"
                readonly
                class="address-textarea"
                @focus="$event.target.select()"
              ></textarea>
              <span v-if="user.shipped" class="shipped-status">已发货</span>
            </div>
            <button 
              :class="['button', { shipped: user.shipped }]" 
              @click="markAsShipped(user)" 
              :disabled="user.shipped"
            >
              {{ user.shipped ? '已发货' : '发货' }}
            </button>
            <button class="button" @click="deleteAddress(user)">删除</button>
            <button class="button" @click="copyToClipboard(user)">复制</button>
          </div>
        </div>
        <p v-else>暂无收货信息</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from 'ethers';
import { contractAddress, addressStorageAbi } from '@/js/contracts.js';

export default {
  name: 'delivery-addresses',
  data() {
    return {
      userAddresses: [],
      isOwner: false,
      contract: null,
      account: null
    };
  },
  computed: {
    truncatedAccount() {
      if (!this.account) return '';
      return `${this.account.slice(0, 6)}...${this.account.slice(-4)}`;
    }
  },
  methods: {
    async goBack() {
      this.$router.push('/settings');
    },
    async initWeb3() {
      if (window.ethereum) {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          await provider.send("eth_requestAccounts", []);
          const signer = provider.getSigner();
          this.account = await signer.getAddress();

          this.contract = new ethers.Contract(contractAddress, addressStorageAbi, signer);

          const owner = await this.contract.getOwner();
          this.isOwner = this.account.toLowerCase() === owner.toLowerCase();

          if (this.isOwner) {
            await this.fetchAddresses();
          } else {
            alert("只有合约部署者可以查看所有收货信息");
          }
        } catch (error) {
          console.error("Error fetching contract owner:", error);
          alert("无法获取合约拥有者，请检查合约地址或ABI配置是否正确");
        }
      } else {
        alert("请安装 MetaMask!");
      }
    },
    async fetchAddresses() {
      try {
        const result = await this.contract.getAllAddresses();
        this.userAddresses = result.map(userInfo => ({
          addressInfo: userInfo.addressInfo,
          phoneNumber: userInfo.phoneNumber,
          shipped: false // 默认未发货状态
        }));
      } catch (error) {
        console.error("Error fetching addresses: ", error);
        alert("无法获取地址信息，请重试");
      }
    },
    async deleteAddress(user) {
      if (!this.isOwner) {
        alert("只有合约所有者可以删除信息");
        return;
      }
      try {
        const tx = await this.contract.deleteAddressByInfo(user.addressInfo, user.phoneNumber);
        await tx.wait();
        this.userAddresses = this.userAddresses.filter(u => u !== user);
        alert("地址已删除");
      } catch (error) {
        console.error("Error deleting address: ", error);
        alert("删除地址时出错，请重试");
      }
    },
    addressText(user) {
      return `信息: ${user.addressInfo || ''}\n电话: ${user.phoneNumber || ''}`;
    },
    markAsShipped(user) {
      if (confirm("确定要标记为已发货吗？")) {
        user.shipped = true;
        alert("标记为已发货");
      }
    },
    copyToClipboard(user) {
      const addressInfo = this.addressText(user);
      navigator.clipboard.writeText(addressInfo).then(() => {
        alert("地址信息已复制到剪贴板");
      }, (err) => {
        console.error("Could not copy text: ", err);
        alert("复制失败，请重试");
      });
    }
  },
  async mounted() {
    await this.initWeb3();
  }
};
</script>

<style scoped>
@import "@/assets/css/style.css";

.main {
  padding-top: 50px;
  height: calc(100vh - 100px); /* 确保内容在屏幕内并有滚动余地 */
  overflow-y: auto; /* 添加垂直滚动条 */
}

.top button {
  margin-right: 10px;
}

.button {
  margin-right: 5px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

button.shipped {
  background-color: red;
}

.address-item {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
}

.address-textarea {
  width: 100%;
  height: 40px;
  margin-top: 5px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
  background-color: #f9f9f9;
}

.wallet-info {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.shipped-status {
  display: block;
  color: red;
  font-weight: bold;
  margin-top: 5px;
}
</style>