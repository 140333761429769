<template>
    <div class="maxbox">
        <div>
            <div class="top">
                <p>设置</p>
            </div>
            <div class="main">
                <button class="return" @click="goback"><img src="@/assets/images/left-arrow.png" alt=""></button>
                <div class="main-content">
                    <!-- 只有合约拥有者可以看到和使用的功能 -->
                    <template v-if="isOwner">
                        <button @click="calculateDividends">计算分红</button>
                        <label for="recipientAddress">接收地址：</label>
                        <input type="text" id="recipientAddress" v-model="recipientAddress" placeholder="请输入接收地址" />
                        <label for="transferAmount">金额(USDT)：</label>
                        <input type="number" id="transferAmount" v-model="transferAmount" placeholder="请输入转账金额 (USDT)" />
                        <button id="transferFundsBtn" @click="transferContractUSDTBalance">转账</button>
                        <button @click="withdrawAllFunds">一键提取所有资金</button>
                        <button @click="viewDeliveryAddresses">查看收货地址</button>
                        <p>合约USDT余额：{{ contractBalance }} USDT</p>
                        <p>拨出的总奖金：{{ totalDividendsDistributed }} USDT</p>

                        <label for="accountAddress">要冻结/解冻结的账户地址：</label>
                        <input type="text" id="accountAddress" v-model="accountAddress" placeholder="请输入账户地址" />
                        <button @click="freezeAccount">冻结账户</button>
                        <button @click="unfreezeAccount">解冻账户</button>
                        <button @click="checkAccountStatus">查询账户是否被冻结</button>
                        <p v-if="accountStatus !== null">账户冻结状态：{{ accountStatus ? '被冻结' : '未冻结' }}</p>
                    </template>
                    <!-- 非合约拥有者看到的信息 -->
                    <div v-else>您不是合约拥有者，无法查看或执行这些操作。</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ethers } from 'ethers';
import { contractAddress, contractABI } from '@/js/contract.js';

export default {
    name: 'settings',
    data() {
        return {
            recipientAddress: '',
            transferAmount: '',
            contractBalance: '0',
            isOwner: false,
            totalDividendsDistributed: '0',
            accountAddress: '',
            accountStatus: null,
            provider: null,
            signer: null,
            contract: null
        };
    },
    mounted() {
        this.initializeContract();
    },
    methods: {
        goback() {
            this.$router.go(-1);
        },
        // 初始化合约连接
        async initializeContract() {
            await this.connectWallet();
            await this.checkOwnership();
            if (this.isOwner) {
                await this.getContractUSDTBalance();
                await this.getTotalDividendsDistributed();
            }
        },
        // 连接钱包
        async connectWallet() {
            try {
                if (typeof window.ethereum !== 'undefined') {
                    this.provider = new ethers.providers.Web3Provider(window.ethereum);
                    await this.provider.send("eth_requestAccounts", []);
                    this.signer = this.provider.getSigner();
                    this.contract = new ethers.Contract(contractAddress, contractABI, this.signer);
                } else {
                    alert('未检测到以太坊钱包，请安装MetaMask。');
                }
            } catch (error) {
                console.error('连接钱包失败:', error);
                alert('连接钱包失败！');
            }
        },
        // 检查当前用户是否为合约拥有者
        async checkOwnership() {
            try {
                const currentAccount = await this.signer.getAddress();
                const ownerAddress = await this.contract.owner();
                this.isOwner = currentAccount.toLowerCase() === ownerAddress.toLowerCase();
            } catch (error) {
                console.error('检查合约拥有者失败:', error);
            }
        },
        // 计算分红
        async calculateDividends() {
            try {
                const tx = await this.contract.distributeDividends();
                await tx.wait();
                alert('分红计算成功！');
                // 更新合约余额和总分红金额
                await this.getContractUSDTBalance();
                await this.getTotalDividendsDistributed();
            } catch (error) {
                console.error('分红计算失败:', error);
                alert('分红计算失败：' + error.message);
            }
        },
        // 转移合约USDT余额
        async transferContractUSDTBalance() {
            if (!this.recipientAddress || !this.transferAmount) {
                alert('请填写接收地址和转账金额。');
                return;
            }

            if (!ethers.utils.isAddress(this.recipientAddress)) {
                alert('无效的接收地址。');
                return;
            }

            try {
                const tx = await this.contract.transferFunds(this.recipientAddress, ethers.utils.parseUnits(this.transferAmount, 6));
                await tx.wait();
                alert('转账成功！');
                await this.getContractUSDTBalance();
            } catch (error) {
                console.error('转账失败:', error);
                alert('转账失败：' + error.message);
            }
        },
        // 获取合约USDT余额
        async getContractUSDTBalance() {
            try {
                const balance = await this.contract.getContractBalance();
                this.contractBalance = ethers.utils.formatUnits(balance, 6);
            } catch (error) {
                console.error('查询合约USDT余额失败:', error);
                alert('查询合约USDT余额失败！');
            }
        },
        // 获取总分红金额
        async getTotalDividendsDistributed() {
            try {
                const totalDividends = await this.contract.getTotalDistributedBonuses();
                this.totalDividendsDistributed = ethers.utils.formatUnits(totalDividends, 6);
            } catch (error) {
                console.error('查询拨出的总奖金失败:', error);
                alert('查询拨出的总奖金失败！');
            }
        },
        // 一键提取所有资金
        async withdrawAllFunds() {
            try {
                const tx = await this.contract.withdrawAllFunds();
                await tx.wait();
                alert('所有资金已成功提取！');
                await this.getContractUSDTBalance();
            } catch (error) {
                console.error('提取所有资金失败:', error);
                alert('提取所有资金失败：' + error.message);
            }
        },
        // 查看收货地址
        viewDeliveryAddresses() {
            this.$router.push({ path: '/delivery-addresses' });
        },
        // 冻结账户
        async freezeAccount() {
            if (!ethers.utils.isAddress(this.accountAddress)) {
                alert('无效的账户地址。');
                return;
            }

            try {
                const tx = await this.contract.freezeAccount(this.accountAddress);
                await tx.wait();
                alert('账户冻结成功！');
            } catch (error) {
                console.error('冻结账户失败:', error);
                alert('冻结账户失败：' + error.message);
            }
        },
        // 解冻账户
        async unfreezeAccount() {
            if (!ethers.utils.isAddress(this.accountAddress)) {
                alert('无效的账户地址。');
                return;
            }

            try {
                const tx = await this.contract.unfreezeAccount(this.accountAddress);
                await tx.wait();
                alert('账户解冻成功！');
            } catch (error) {
                console.error('解冻账户失败:', error);
                alert('解冻账户失败：' + error.message);
            }
        },
        // 查询账户是否被冻结
        async checkAccountStatus() {
            if (!ethers.utils.isAddress(this.accountAddress)) {
                alert('无效的账户地址。');
                return;
            }

            try {
                const status = await this.contract.isAccountFrozen(this.accountAddress);
                this.accountStatus = status;
            } catch (error) {
                console.error('查询账户冻结状态失败:', error);
                alert('查询账户冻结状态失败！');
            }
        }
    }
}
</script>

<style scoped>
@import "@/assets/css/settings.css";
@import "@/assets/css/style.css";

.main {
    padding-top: 50px;
}
</style>