import Vue from 'vue'
import Router from 'vue-router'
import homeview from "@/components/home.vue"
import itemview from "@/components/item.vue"
import userview from "@/components/user.vue"
import myteam from "@/components/myteam.vue"
import settingsview from "@/components/settings.vue"
import incomeview from "@/components/income.vue"
import copyinviteview from "@/components/copy-invite.vue"
import deliveryaddressesview from "@/components/delivery-addresses.vue"

Vue.use(Router)

const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        component: homeview
    },
    {
        path: '/user',
        component: userview
    },
    {
        path: '/myteam',
        component: myteam
    },
    {
        path: '/settings',
        component: settingsview
    },
    {
        path: '/income',
        component: incomeview
    },
    {
        path: '/copy-invite',
        component: copyinviteview
    },
    {
        path: '/item',
        component: itemview
    },
    {
        path: '/delivery-addresses',
        component: deliveryaddressesview
    }
]

export default new Router({
    routes
})