<template>
    <div>
        <div class="maxbox">
            <div>
                <div class="top">
                    <p>我的社区</p>
                </div>
                <div class="main">
                    <button class="return" @click="goback">
                        <img src="@/assets/images/left-arrow.png" alt="" />
                    </button><br>

                    <div class="number">
                        <div class="inline-info">
                            <p>我的上级结点:</p>
                            <span id="referrer">{{ formatAddress(referrer) }}</span>
                        </div>
                        <div class="inline-info">
                            <p>我推荐的会员人数：</p>
                            <span id="v2ReferralsCount">{{ totalReferrals }}</span>
                        </div>
                        <p class="inline-info">
                            <span id="totalReferrals">我的团队总人数: {{ totalAssociatedAddresses }}</span>
                        </p>
                    </div><br>

                    <div id="referrals" class="referrals-container">
                        <div v-for="referral in referrals" :key="referral.address" class="pyuser">
                            <img src="@/assets/images/pyusers.png" alt="" />
                            <p :class="{ inactive: referral.status === 'Inactive' }">
                                {{ formatAddress(referral.address) }}
                                <span class="up" v-if="referral.status !== 'Inactive'">已激活 ({{ referral.status }})</span>
                                <span class="up" v-else>未激活</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ethers } from "ethers";
import { contractAddress, contractABI } from "@/js/contract.js";

export default {
    name: "myteam",
    data() {
        return {
            totalAssociatedAddresses: 0,
            totalReferrals: 0,
            referrer: "",
            referrals: [],
            pageSize: 10, // Only fetch 20 latest referrals
            loading: false,
        };
    },
    methods: {
        goback() {
            this.$router.go(-1);
        },
        async connectWallet() {
            if (window.ethereum) {
                try {
                    await window.ethereum.request({ method: "eth_requestAccounts" });
                    return new ethers.providers.Web3Provider(window.ethereum);
                } catch (error) {
                    console.error("用户拒绝了账户访问请求", error);
                }
            } else {
                console.error("未检测到Ethereum提供商。请安装MetaMask。");
            }
        },
        async fetchData() {
            if (this.loading) return;
            this.loading = true;

            const provider = await this.connectWallet();
            if (!provider) return;

            const signer = provider.getSigner();
            const contract = new ethers.Contract(contractAddress, contractABI, signer);

            try {
                const userAddress = await signer.getAddress();
                if (!userAddress) {
                    alert("无法检测到用户地址，请先连接到MetaMask。");
                    this.loading = false;
                    return;
                }

                const [totalAssociatedAddresses, allTeamMembers, referrer, totalReferrals] = await Promise.all([
                    contract.getTotalAssociatedAddresses(userAddress),
                    contract.getTeamMembers(userAddress),
                    contract.getReferrer(),
                    contract.getTotalReferrals()
                ]);

                this.totalAssociatedAddresses = totalAssociatedAddresses.toNumber();
                this.referrer = referrer;
                this.totalReferrals = totalReferrals.toNumber();

                // Take the latest 'pageSize' number of team members
                const latestTeamMembers = allTeamMembers.slice(-this.pageSize);

                const referralDetails = await Promise.all(
                    latestTeamMembers.map(async (address) => {
                        const status = await contract.getMemberStatus(address);
                        return { address, status };
                    })
                );

                this.referrals = referralDetails;

            } catch (error) {
                console.error("查询数据失败", error);
                alert("查询失败，请确保已连接到MetaMask并检查网络。");
            } finally {
                this.loading = false;
            }
        },
        formatAddress(address) {
            if (!address) return "";
            return `${address.slice(0, 6)}...${address.slice(-4)}`;
        }
    },
    mounted() {
        this.fetchData();
    },
};
</script>

<style scoped>
@import "@/assets/css/team.css";
@import "@/assets/css/style.css";

.pyuser p {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.inline-info {
    display: flex;
    align-items: center;
    gap: 5px;
}

.inactive {
    color: gray;
}

.main {
    padding-top: 50px;
}

.referrals-container {
    max-height: 400px;
    overflow-y: auto;
}
</style>